@media screen and (min-width: 1024px) {
  input::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .ListWidget_Body_Scroll::-webkit-scrollbar {
    width: 8px;
  }

  .ListWidget_Body_Scroll::-webkit-scrollbar-track {
    width: 8px;
    background-color: #f0f0f0;
  }

  .ListWidget_Body_Scroll::-webkit-scrollbar-thumb {
    background-color: rgb(204, 204, 204);
    border-radius: 10px;
  }

  .ListWidget_List_Button:hover {
    background-color: rgb(55, 105, 252);
    transition: 0.7s;
  }

  .ListWidget_List_Button:hover .List_Body_CompanyName {
    color: white;
  }

  .ListWidget_List_Button:hover .List_Body_BusinessPeriod {
    color: white;
  }

  .ListWidget_List_Button:hover .List_Body_InvestmentPhase {
    color: white;
  }

  .ListWidget_List_Button:hover .List_Body_EmployeesNumber {
    color: white;
  }

  .ListWidget_List_Button:hover .List_Body_AverageAnnualSalary {
    color: white;
  }

  .ListWidget_List_Button:hover .List_Body_InvestmentAmount {
    color: white;
  }

  .ListWidget_List_Button:hover .List_Body_AnnualLaborCosts {
    color: white;
  }

  .ListWidget_List_Button:hover .List_Body_InvestmentExhaustion {
    color: white;
  }

  .Flex_App {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .GNB_BG {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 48px;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: center;
  }

  .GNB_BG_V {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    left: 0px;
    width: 237px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: flex-start;

    border-style: solid;
    border-color: rgb(242, 244, 246);
    border-right-width: 3px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
  }

  .GNB_LeftContents {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    margin-left: 4vw;
  }

  .GNB_LogoCT_V {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-top: 24px;
  }

  .GNB_Upper_V {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .GNB_Lower_V {
    display: flex;
    flex-direction: column;
  }

  .GNB_MenuBar_CT {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .GNB_MenuBar_BG_On {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 220px;
    height: 40px;
    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(231, 239, 255);
  }

  .GNB_MenuBar_BG_Off {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 220px;
    height: 40px;
    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
  }

  .GNB_MenuBar_Contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .GNB_MenuBar_Label_On {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-left: 12px;
  }

  .GNB_MenuBar_Label_Off {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-left: 12px;
  }

  .GNB_MenuBar_Divider {
    margin-top: 8px;
    margin-bottom: 12px;
    margin-left: 10px;
    width: 220px;
    height: 2px;
    background-color: rgb(227, 227, 227);
  }

  .GNB_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
  }

  .GNB_Logo {
    display: flex;
    z-index: 1;
    width: 78px;
    height: 18px;
  }

  .GNB_Logo_V {
    display: flex;
    z-index: 1;
    width: 81px;
    height: 29px;
  }

  .GNB_CompanyProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .GNB_CompanyLogo {
    display: flex;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
  }

  .GNB_CompanyName_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-left: 8px;
  }

  .GNB_SettingIcon_CT {
    display: flex;
    z-index: 1;
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }

  .GNB_RightContents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  .GNB_Logout_CT {
    display: flex;
  }

  .GNB_Profile_CT {
    display: flex;
    margin-left: 12vw;
  }

  .GNB_Logout_Button {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .Footer_Contents_CT {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 12px;
  }

  .Footer_Button {
    display: flex;
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Footer_Logo {
    display: flex;
    width: 79.5px;
    height: 18px;
  }

  .Footer_Texts {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  .Footer_Text {
    display: flex;
    margin-bottom: 4px;
  }

  .Footer_Text_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.4px;
  }

  .Screen_CT {
    display: flex;
    flex-direction: row;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
    background-color: #fcfcfc;
  }

  .Login_Screen_CT {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 160px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }

  .Login_Screen_Contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .Login_Title {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    margin-top: 64px;
    margin-bottom: 48px;
  }

  .Login_Signin_TextButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .Login_Signin_Text {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .Contents_Area_CT {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    padding-bottom: 72px;
    width: 100%;
    /* width: 1238px; */
    min-width: 640px;
    max-width: 1238px;
    height: auto;
  }

  .Contents_Area_Left_Margin {
    width: 24%;
    max-width: 460px;
    min-width: 264px;
  }

  .Contents_Area_Right_Margin {
    width: 4%;
    max-width: 220px;
    min-width: 24px;
  }

  .PageTitle_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-bottom: 32px;
  }

  .PageTitle_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  .PageTitle_Icon_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .PageTitle_Title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  }

  .Widget_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1238px;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 1px;
    border-radius: 12px;
    background-color: white;
  }

  .Widget_OneThird_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    max-width: 394px;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 1px;
    border-radius: 12px;
    background-color: white;
  }

  .Widget_Short_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 366px;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 1px;
    border-radius: 12px;
    background-color: white;
    padding-top: 24px;
  }

  .Widget_Title_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .Widget_Title_Copy {
    display: flex;
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Widget_Title_Left_Area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 24px;
  }

  .Widget_Title_Right_Area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 24px;
  }

  .Widget_Button_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border-style: none;
    padding: 0px;
    margin: 0px;
  }

  .Widget_Button_Label {
    display: flex;
    color: #7a7a7a;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .Widget_Button_Left_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
  }

  .Widget_Button_Right_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
  }

  .Widget_Title_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .Widget_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
  }

  .ListWidget_List_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1238px;
    width: 100%;
    box-sizing: border-box;
  }

  .ListWidget_Label_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .List_Label_CompanyName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    min-width: 54px;
    margin-left: 12px;
    margin-right: 24px;
  }

  .List_Label_BusinessPeriod {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 46px;
    max-width: 46px;
    margin-right: 24px;
  }

  .List_Label_StockAmount {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 74px;
    max-width: 74px;
    margin-right: 24px;
  }

  .List_Label_StockPrice {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 66px;
    max-width: 66px;
    margin-right: 24px;
  }

  .List_Label_InvestmentMoney {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 122px;
    max-width: 122px;
    margin-right: 24px;
  }

  .List_Label_InvestmentPhase {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 54px;
    max-width: 54px;
    margin-right: 24px;
  }

  .List_Label_Date {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 74px;
    max-width: 74px;
    margin-right: 24px;
  }

  .List_Label_EmployeesNumber {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 72px;
    max-width: 72px;
    margin-right: 24px;
  }

  .List_Label_AverageAnnualSalary {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 102px;
    max-width: 102px;
    margin-right: 24px;
  }

  .List_Label_InvestmentAmount {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 122px;
    max-width: 122px;
    margin-right: 24px;
  }

  .List_Label_AnnualLaborCosts {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 154px;
    max-width: 154px;
    margin-right: 24px;
  }

  .List_Label_InvestmentExhaustion {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 120px;
    max-width: 120px;
    margin-right: 8px;
  }

  .List_Label_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #aaaaaa;
  }

  .ListWidget_List_Button {
    display: flex;
    flex-direction: column;
    border-style: none;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .ListWidget_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ListWidget_Body_Scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: white;
    margin-bottom: 24px;
    margin-top: 12px;
  }

  .List_Body_CompanyName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    width: 100%;
    max-width: 100%;
    min-width: 54px;
    margin-left: 12px;
    margin-right: 24px;
  }

  .List_Body_BusinessPeriod {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    min-width: 46px;
    max-width: 46px;
    margin-right: 24px;
  }

  .List_Body_StockAmount {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 74px;
    max-width: 74px;
    text-align: left;
    margin-right: 24px;
  }

  .List_Body_StockPrice {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 66px;
    max-width: 66px;
    margin-right: 24px;
  }

  .List_Body_InvestmentMoney {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 122px;
    max-width: 122px;
    margin-right: 24px;
  }

  .List_Body_InvestmentPhase {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 54px;
    max-width: 54px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_Date {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 74px;
    max-width: 74px;
    margin-right: 24px;
  }

  .List_Body_EmployeesNumber {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 72px;
    max-width: 72px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_AverageAnnualSalary {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 102px;
    max-width: 102px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_InvestmentAmount {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 122px;
    max-width: 122px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_AnnualLaborCosts {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 154px;
    max-width: 154px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_InvestmentExhaustion {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 120px;
    max-width: 120px;
    text-align: left;
  }

  .List_Body_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
  }

  .Input_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 320px;
  }

  .Input_Label_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
  }

  .Input_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .Input_Label_Required_Dot {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-left: 4px;
  }

  .Input_Short_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 302px;
    height: 34px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Input_Short_Row_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .Input_Short_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 36px;
    border-radius: 8px;
    border-style: none;
    background-color: #e7efff;
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin-left: 12px;
  }

  .Input_Short_Button_Disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 36px;
    border-radius: 8px;
    border-style: none;
    background-color: #e3e3e3;
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin-left: 12px;
  }

  .Input_Feedback_Text_Confirm {
    color: #03a600;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }

  .Input_Feedback_Text_Error {
    color: #ff7373;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }

  .BasicButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 48px;
    border-radius: 8px;
    border-style: none;
    cursor: pointer;
  }

  .BasicButton_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .BasicButton_Label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  .DescriptionWidget_Data_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 4px;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1238px;
    width: 100%;
    box-sizing: border-box;
  }

  .DescriptionWidget_Data_Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 50%;
  }

  .DescriptionWidget_Data_Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    box-sizing: border-box;
    width: 50%;
  }

  .DescriptionWidget_Data_Vertical_Divider {
    height: calc(100% - 20px);
    width: 1px;
    background-color: #e3e3e3;
  }

  .DescriptionWidget_Data_Item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* width: 572px; */
    width: 100%;
    margin-bottom: 20px;
  }

  .DescriptionWidget_Data_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 50px;
    max-width: 50px;
    margin-right: 32px;
    text-align: left;
  }

  .DescriptionWidget_Data_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    max-width: 491px;
    text-align: left;
  }

  .DescriptionWidget_Data_Detail {
    color: #7a7a7a;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-left: 4px;
    width: 100%;
    text-align: left;
  }

  .GraphWidget_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
  }

  .GraphWidget_Data_CT {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1238px;
    width: 100%;
    box-sizing: border-box;
  }

  .GraphWidget_Data_Title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 1238px;
    width: 100%;
    box-sizing: border-box;
  }

  .GraphWidget_Data_Title_Copy {
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
  }

  .GraphWidget_Data_Title_Badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 24px;
    background-color: #e7efff;
    border-style: none;
    border-radius: 8px;
    margin-left: 8px;

    color: #77a8ff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }

  .GraphWidget_Data_Title_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .GraphWidget_Data_Graph {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1238px;
    width: 100%;
    height: 207px;
  }

  .DonutChart_Inner_Data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  .DonutChart_Inner_Data_Badge {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-radius: 11px;
    padding-left: 4px;
    padding-right: 4px;
    height: 22px;
    background-color: #e7efff;
    margin-bottom: 8px;

    color: #77a8ff;
    font-family: "Pretendard", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
  }

  .DonutChart_Inner_Data_Text {
    color: #464646;
    font-family: "Pretendard", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
  }

  .GraphWidget_Data_Label_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 24px;
  }

  .GraphWidget_Data_Label_Item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .GraphWidget_Data_Label_Box {
    height: 14px;
    width: 14px;
    margin-right: 4px;
  }

  .DonutChart_Data_Label_Title {
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
  }

  .DonutChart_Data_Label_Vertical_Divider {
    height: 12px;
    width: 1px;
    background-color: #aaaaaa;
    margin-left: 4px;
    margin-right: 4px;
  }

  .DonutChart_Data_Label_Body {
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }

  .BarChart_CT {
    display: flex;
    position: relative;
    width: 346px;
    height: 165px;
    border-style: none;
  }

  .BarChart_Grid_BG {
    display: flex;
    position: absolute;
    width: 100%;
    height: 149px;
    flex-direction: column;
    justify-content: space-between;
  }

  .BarChart_Chart_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 32px;
    width: 100%;
    height: 165px;
    margin-left: 18px;
  }

  .BarChart_Chart_Bar_CT {
    display: flex;
    height: 135px;
    flex-direction: row;
    align-items: flex-end;
  }

  .BarChart_Chart_Bar {
    display: flex;
    width: 26px;
    z-index: 100;
    margin-right: 24px;
    background-color: #e7efff;
  }

  .BarChart_Chart_Label_CT {
    display: flex;
    flex-direction: row;
  }

  .BarChart_Chart_Label {
    color: #7a7a7a;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    width: 26px;
    margin-right: 24px;
  }

  .BarChart_GridLine_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .BarChart_GridLabel {
    display: flex;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
    font-size: 12px;
  }

  .BarChart_GridLine {
    display: flex;
    width: calc(100% - 28px);
    height: 1px;
    background-color: #e3e3e3;
  }

  .BarChart_Tooltip {
    background-color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    color: #3769fc;
    text-align: center;
    padding: 4px 8px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: #3769fc;
    font-size: 12px;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -100%);
    z-index: 1000;
    white-space: nowrap;
  }

  .LineChart_CT {
    display: flex;
    position: relative;
    width: 346px;
    height: 165px;
    border-style: none;
  }

  .LineChart_Chart_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 32px;
    width: 100%;
    height: 165px;
    margin-left: 18px;
  }

  .LineChart_Grid_BG {
    display: flex;
    position: absolute;
    width: 100%;
    height: 149px;
    flex-direction: column;
    justify-content: space-between;
  }

  .LineChart_GridLine_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .LineChart_GridLabel {
    display: flex;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    color: #aaaaaa;
    text-align: center;
    font-size: 12px;
  }

  .LineChart_GridLine {
    width: calc(100% - 28px);
    height: 1px;
    background-color: #e3e3e3;
  }

  .LineChart_SVG {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* background-color: #e7efff; */
    width: 276px;
    height: 135px;
    z-index: 998;
    overflow: visible;
  }

  .LineChart_Chart_Label_CT {
    display: flex;
    flex-direction: row;
  }

  .LineChart_Chart_Label {
    color: #7a7a7a;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    width: 26px;
  }

  .LineChart_Tooltip {
    background-color: #ffffff;
    padding: 4px 8px;
    border: 1px solid #3769fc;
    border-radius: 8px;
    font-family: "Pretendard";
    font-size: 12px;
    color: #3769fc;
    position: absolute;
    transform: translate(-50%, -100%);
    z-index: 999;
    pointer-events: none;
    white-space: nowrap;
  }

  .TabMenuListWidget_Title_Tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-style: none;
    background-color: transparent;
    margin-right: 24px;
    cursor: pointer;
  }

  .TabMenuList_Label_DocumentName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    max-width: 1048px;
    min-width: 54px;
    margin-left: 12px;
    margin-right: 24px;
  }

  .TabMenuList_Body_DocumentName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    max-width: 1048px;
    min-width: 54px;
    margin-left: 12px;
    margin-right: 24px;
    text-align: left;
  }

  .ListWidget_List_Button:hover .TabMenuList_Body_DocumentName {
    color: white;
  }

  .TabMenuList_Label_IssueDate {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    max-width: 74px;
    min-width: 74px;
    margin-right: 24px;
  }

  .TabMenuList_Label_ReportYearMonth {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    max-width: 90px;
    min-width: 90px;
    margin-right: 24px;
  }

  .TabMenuList_Body_IssueDate {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    max-width: 74px;
    min-width: 74px;

    margin-right: 24px;
    text-align: left;
  }

  .TabMenuList_Body_ReportYearMonth {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    max-width: 90px;
    min-width: 90px;
    margin-right: 24px;
    text-align: left;
  }

  .ListWidget_List_Button:hover .TabMenuList_Body_IssueDate {
    color: white;
  }

  .ListWidget_List_Button:hover .TabMenuList_Body_ReportYearMonth {
    color: white;
  }

  .BarChartGroupWidget_Group_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 48px;
    box-sizing: border-box;
  }

  .BarChartGroupWidget_Group_Title_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .BarChartGroupWidget_Group_Title {
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }

  .BarChartGroupWidget_Group_Button {
    border-style: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  .BarChartGroupWidget_Group_Title_Divider {
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    margin-top: 8px;
  }

  .BarChartGroupWidget_Group_Graph_Line_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .BarChartGroupWidget_Group_Graph_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 354px;
    margin-top: 24px;
  }

  .BarChartGroupWidget_Group_Graph_Title_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .BarChartGroupWidget_Group_Graph_Title {
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .BarChartGroupWidget_Group_Graph_Body {
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }

  .BarChartGroupWidget_Group_Graph_Divider {
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    margin-bottom: 16px;
  }

  .BreakdownGroupComponent_Group_Detail_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;
  }

  .BreakdownGroupComponent_Group_Detail_Label {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
  }

  .BreakdownGroupComponent_Group_Detail_Value {
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: right;
  }

  .BreakdownGroupComponent_Group_Detail_Vertical_Divider {
    height: 100%;
    width: 1px;
    background-color: #e3e3e3;
  }

  .List_Body_Detail_Button {
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 25px;
    max-width: 25px;
    text-decoration: underline;

    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .NoteGroupWidget_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    line-height: 150%;
    width: 100%;
    max-width: 1192px;
    margin-top: 24px;
  }

  .Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .Modal_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 480px;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-style: none;
    border-radius: 12px;
  }

  .Modal_Title_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Modal_Title {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }

  .Modal_Close_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    border-style: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Modal_Body_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .Modal_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  .Footer_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 65%;
    min-width: 1092px;
    margin-bottom: 24px;
  }

  .Footer_Item_Row_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 16px;
  }

  .Footer_LogoButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 16px;
    border-style: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  .Footer_Item_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .Footer_Item_Text {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }

  .Display_None {
    display: none;
  }

  .Create_Seal_Image_Button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 36px;
    border-radius: 8px;
    border-style: none;
    background-color: #03a600;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  .Create_Seal_Image_Button_Label_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Create_Seal_Image_Button_Copy {
    color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .Create_Seal_Image_Button_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CreateSeal_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .CreateSeal_Modal_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 416px;
    padding: 32px;
    background-color: white;
    border-style: none;
    border-radius: 12px;
  }

  .CreateSeal_Title_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .CreateSeal_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .CreateSeal_Icon_CT {
    width: 32px;
    height: 32px;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .CreateSeal_Divider {
    width: 100%;
    height: 1px;
    margin-top: 12px;
    margin-bottom: 32px;
    background-color: rgb(170, 170, 170);
  }

  .CreateSeal_Input_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .CreateSeal_Input {
    display: flex;
    flex-direction: row;
    width: 314px;
    height: 38px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 12px;
    outline: none;
  }

  .CreateSeal_GenerateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 42px;
    padding: 0px;
    margin: 0px;
    background-color: rgb(231, 239, 255);
    border-style: none;
    border-radius: 8px;
    cursor: pointer;

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .CreateSeal_Info_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 150%;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .CreateSeal_Canvas_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .CreateSeal_DownloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    padding: 0px;
    margin: 0px;
    background-color: rgb(55, 105, 252);
    border-style: none;
    border-radius: 8px;
    cursor: pointer;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  .DescriptionWidget_Button_Vertical_Divider {
    height: 16px;
    width: 2px;
    background-color: #e3e3e3;
    margin-left: 6px;
    margin-right: 6px;
  }

  .DescriptionWidget_NoItem_Copy {
    color: #7a7a7a;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .InputShort_Unit {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
    text-align: left;
  }

  .box_section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 50px 50px 50px 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    color: #333d4b;
    align-items: center;
    text-align: center;
    overflow-x: auto;
    white-space: nowrap;
  }

  .box_section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 540px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 48px 48px 48px 48px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    overflow-x: auto;
    white-space: nowrap;
  }

  .PaymentConfirm_Category_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .PaymentConfirm_Left_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .PaymentConfirm_Label_Copy {
    color: #3182f6;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .Payment_CT {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .Report_PDF_Button {
    width: 96px;
    height: 32px;

    color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    background-color: #3182f6;
    margin: 0px;
    padding: 0px;
    cursor: pointer;

    border-style: none;
    border-radius: 8px;
  }
}
